<template>
	<div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 角色管理
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="">
			<div class="handle-box">
				<el-button type="primary" icon="el-icon-lx-add" @click="roleVisible=true;">添加角色</el-button>
			</div>
			<el-table :data="roles" border class="table" ref="multipleTable" header-cell-class-name="table-header">
				<el-table-column prop="role_name" label="角色名称"></el-table-column>
				<el-table-column prop="description" label="角色说明"></el-table-column>
				<el-table-column label="操作" width="380" align="center">
					<template #default="scope">
						<el-button type="text" icon="el-icon-edit" @click="menuPermission(scope.row.id);">设置权限
						</el-button>
						<el-button type="text" icon="el-icon-edit" @click="roleVisible=true;roleForm=scope.row">编辑
						</el-button>
						<el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.row.id)">删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next"
					:current-page="cur_page" :total="total" :page-size="pageSize"></el-pagination>
			</div>
		</div>
		<!-- 编辑弹出框 -->
		<el-dialog title="角色管理" v-model="roleVisible" width="30%">
			<el-form ref="form" :model="roleForm" label-width="70px">
				<el-form-item label="角色名称">
					<el-input v-model="roleForm.role_name"></el-input>
				</el-form-item>
				<el-form-item label="角色说明">
					<el-input v-model="roleForm.description"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="roleVisible = false">取 消</el-button>
					<el-button type="primary" @click="roleAction">确 定</el-button>
				</span>
			</template>

		</el-dialog>
		<!-- 编辑弹出框 -->
		<el-dialog title="角色权限管理" v-model="rolePermissionVisible" width="30%">
			<el-tree v-if="rolePermissionVisible" :data="rolePermissionFrom" show-checkbox default-expand-all
				node-key="id" :default-checked-keys="hasPermissionFrom" ref="tree" highlight-current
				:props="defaultProps">
				<template #default="{ node, data }">
					<span class="custom-tree-node">
						<span>{{ node.label }}({{data.pid>=0?'菜单':'权限'}})</span>
					</span>
				</template>
			</el-tree>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="rolePermissionVisible = false">取 消</el-button>
					<el-button type="primary" @click="rolePermissionAction">确 定</el-button>
				</span>
			</template>

		</el-dialog>
	</div>
</template>
<script>
	export default {
		name: 'basetable',
		data() {
			return {
				roleVisible: false,
				roleForm: {},
				roles: [],
				total: 0,
				pageSize: 10,
				cur_page: 1,
				rolePermissionVisible: false,
				rolePermissionFrom: [],
				hasPermissionFrom: [],
				defaultProps: {
					children: 'children',
					label: 'name',
					pid: 'pid'
				},
				role_id: 0,
			};
		},
		created() {
			this.getRoles();
		},
		methods: {
			// 分页导航
			handleCurrentChange(val) {
				this.cur_page = val
				this.getRoles()
			},
			getRoles() {
				this.$axios({
					url: 'admin/role/getRoleInfo',
					method: 'post',
					params: {
						page: this.cur_page
					},
				}).then(({
					code,
					data: {
						data,
						total
					}
				}) => {
					if (code == 200) {
						this.roles = data
						this.pageTotal = total
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			roleAction() {
				let url;
				if (this.roleForm.id) {
					url = 'admin/role/updateRole'
				} else {
					url = 'admin/role/addRole'
				}
				this.$axios({
					url,
					method: 'post',
					data: {
						...this.roleForm
					}
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						this.$message.success(msg)
						this.roleVisible = false;
						this.getRoles()
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},

			menuPermission(role_id) {
				this.role_id = role_id
				this.$axios({
					url: 'admin/role/getRuleList',
					method: 'get',
					params: {
						role_id
					}
				}).then(({
					code,
					data
				}) => {
					if (code == 200) {
						console.log(data)
						this.rolePermissionFrom = data.tree_data
						this.hasPermissionFrom = data.tree_has
						this.rolePermissionVisible = true
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			rolePermissionAction() {
				console.log(this.$refs.tree.getCheckedNodes());
				// console.log(this.$refs.tree.getCheckedKeys());
				let allSelected = this.$refs.tree.getCheckedNodes();
				let operation_ids = [];
				for (let item in allSelected) {
					if (!allSelected[item].pid) {
						operation_ids.push(allSelected[item].id);
					}
				}
				console.log(operation_ids)

				this.$axios({
					url: '/admin/role/assignRules',
					method: 'post',
					data: {
						operation_ids,
						role_id: this.role_id
					}
				}).then(({
					code,
					data
				}) => {
					if (code == 200) {
						console.log(data)
						this.$message.success('设置成功');
						this.rolePermissionVisible = false
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},

			// 删除操作
			handleDelete(role_id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						console.log(123)
						this.$axios({
							url: '/admin/role/deleteRole',
							method: 'DELETE',
							params: {
								role_id
							}
						}).then(({
							code,
							// data
						}) => {
							if (code == 200) {
								this.getRoles();
								this.$message.success('删除成功');
							} else {
								//this.$message.error(res.msg)
							}
						}).catch(err => {
							console.log(err)
						});
					})
					.catch(() => {});
			},

			// 编辑操作
			handleEdit(index, row) {
				this.idx = index;
				this.form = row;
				this.editVisible = true;
			},
			// 保存编辑
			saveEdit() {
				this.editVisible = false;
				this.$message.success(`修改第 ${this.idx + 1} 行成功`);
				this.$set(this.tableData, this.idx, this.form);
			},
			// 分页导航
			handlePageChange(val) {
				this.$set(this.query, 'pageIndex', val);
				this.getData();
			}
		}
	};
</script>
<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}
</style>
